var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sorting || _vm.loading)?_c('v-sheet',{attrs:{"color":"appBackground"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-sheet',{attrs:{"color":"appBackground"}},[_c('SubPageNav',{attrs:{"icon":"pi-agent","page-type":"Agent","m":""}},[_c('span',{attrs:{"slot":"page-title"},slot:"page-title"},[_c('span',[_vm._v(" "+_vm._s(_vm.agentName)+" ")])]),_c('span',{style:(_vm.$vuetify.breakpoint.smAndDown && {
          display: 'inline',
          'font-size': '0.875rem'
        }),attrs:{"slot":"breadcrumbs"},slot:"breadcrumbs"},[_c('BreadCrumbs',{attrs:{"crumbs":[
          {
            route: {
              name: 'agents'
            },
            text: 'Agents'
          }
        ]}})],1),_c('span',{class:{ 'mx-auto': _vm.$vuetify.breakpoint.xsOnly },attrs:{"slot":"page-actions"},slot:"page-actions"},[(_vm.agentDetails.status === 'unhealthy')?_c('v-btn',{staticClass:"vertical-button py-1",attrs:{"text":"","tile":"","small":"","color":"error","title":'Remove agent'},on:{"click":function($event){_vm.showConfirmDialog = true}}},[_c('v-icon',[_vm._v(" delete ")]),_c('div',{staticClass:"mb-1"},[_vm._v("Remove")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"480"},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"word-break-normal"},[_vm._v(" Are you sure you want to stop displaying this agent? ")]),_c('v-card-text',{staticClass:"my-4 text-body-2"},[_c('strong',[_vm._v(" This action will not stop the agent process if it is still running in your infrastructure.")]),_vm._v(" It will only stop displaying the agent in Cloud. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showConfirmDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error lighten-1","text":""},on:{"click":_vm.deleteAgent}},[_vm._v(" Confirm ")])],1)],1)],1)],1)]),_c('v-tabs-items',{staticClass:"px-6 mx-auto tabs-border-bottom tab-full-height",style:({
      'padding-top': _vm.$vuetify.breakpoint.smOnly ? '30px' : '80px'
    }),model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pa-0",attrs:{"value":"overview","transition":"tab-fade","reverse-transition":"tab-fade"}},[_c('TileLayout',[_c('AgentFlowRunHistory',{attrs:{"slot":"row-0","agent":_vm.agentDetails},slot:"row-0"}),_c('AgentTile',{attrs:{"slot":"row-1-col-1-tile-1","show-all":"","raw-agent":_vm.agentDetails},slot:"row-1-col-1-tile-1"}),_c('SubmittableRuns',{attrs:{"slot":"row-1-col-2-tile-1","raw-agent":_vm.agentDetails},slot:"row-1-col-2-tile-1"}),_c('AgentRunsInProgress',{attrs:{"slot":"row-1-col-3-tile-1","agent-id":_vm.agentDetails.id,"agent-runs":""},slot:"row-1-col-3-tile-1"}),_c('FlowRunTableTile',{attrs:{"slot":"row-2-col-1-row-2-tile-a","agent-runs":"","agent":_vm.agentDetails},slot:"row-2-col-1-row-2-tile-a"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }