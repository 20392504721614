var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-auto pt-0 px-3 pb-12",attrs:{"fluid":""}},[(_vm.$slots['row-0'])?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_vm._t("row-0")],2)],1):_vm._e(),(
      _vm.$slots['row-1-col-1-tile-1'] ||
        _vm.$slots['row-1-col-2-tile-1'] ||
        _vm.$slots['row-1-col-3-tile-1'] ||
        _vm.$slots['row-1-col-4-tile-1']
    )?_c('v-row',{staticClass:"justify-start",attrs:{"no-gutters":""}},[_c('v-col',{class:{
        'pb-0':
          _vm.$slots['row-2-col-1-row-1-tile-1'] ||
          _vm.$slots['row-2-col-1-row-2-tile-1'] ||
          _vm.$slots['row-2-col-1-row-3-tile-1'] ||
          _vm.$slots['row-2-col-1-row-4-tile-1'],
        'pt-0': _vm.$slots['row-0']
      }},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{class:{
            'pt-2': _vm.$slots['row-0']
          },attrs:{"cols":"12","md":"4"}},[_vm._t("row-1-col-1-tile-1")],2),_c('v-col',{class:{
            'pt-2': _vm.$slots['row-0']
          },attrs:{"cols":"12","md":"4","sm":"6"}},[_vm._t("row-1-col-2-tile-1")],2),_c('v-col',{class:{
            'pt-2': _vm.$slots['row-0']
          },attrs:{"cols":"12","md":"4","sm":"6"}},[_vm._t("row-1-col-3-tile-1")],2)],1)],1),(_vm.$slots['row-2-col-1-row-2-tile-a'])?_c('v-row',{staticClass:"pt-2 mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._t("row-2-col-1-row-2-tile-a")],2)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }